import React from 'react'
/* eslint-disable @typescript-eslint/no-unused-vars */
import EntFeature from 'models/EntFeature'
import { Icon } from 'components'
import { iconsTheme } from 'theme-exports'

export interface INavLink {
    title: string
    icon: React.ReactNode
    feature: EntFeature
    href?: string // omitting creates a category field
    sublinks?: Omit<INavLink, 'icon'>[]
    minimizeDrawer?: Boolean
}

const NavLinks: INavLink[] = [
    {
        title: 'Dashboard',
        href: '/dashboard',
        feature: EntFeature.DASHBOARD,
        icon: <Icon className={iconsTheme.home} />,
    },
    {
        title: 'Pre-Chargebacks',
        href: '/pre-chargebacks',
        feature: EntFeature.PRE_CHARGEBACKS,
        icon: <Icon className={iconsTheme.bell} />,
    },
    {
        title: 'Chargebacks',
        icon: <Icon className={iconsTheme.moneyCheckAlt} />,
        feature: EntFeature.CHARGEBACKS,
        sublinks: [
            {
                title: 'Chargebacks',
                href: '/chargebacks',
                feature: EntFeature.CHARGEBACKS,
                sublinks: [],
            },
            {
                title: ' Invalid Chargebacks',
                href: '/invalid-chargebacks',
                feature: EntFeature.INVALID_CHARGEBACKS,
                sublinks: [],
            },
            {
                title: 'Case Management',
                href: '/case-management',
                feature: EntFeature.CASE_MANAGEMENT,
                sublinks: [],
            },
            {
                title: 'QC Review',
                href: '/case-qc-review',
                feature: EntFeature.CASE_QC_REVIEW,
                sublinks: [],
            },
            {
                title: 'Updater',
                href: '/case-updater',
                feature: EntFeature.CASE_UPDATER,
                sublinks: [],
            },
        ],
    },
    {
        title: 'Alerts',
        href: '/alerts',
        feature: EntFeature.ALERTS,
        icon: <Icon className={iconsTheme.bell} />,
    },
    {
        title: 'Reports',
        icon: <Icon className={iconsTheme.chartPie} />,
        feature: EntFeature.REPORTS,
        sublinks: [
            {
                title: 'Reports',
                href: '/reports',
                feature: EntFeature.REPORTS,
            },
            {
                title: 'Month-to-Date',
                href: '/mtd',
                feature: EntFeature.MTD_REPORTS,
            },
            {
                title: 'MID Health',
                href: '/mid-health',
                feature: EntFeature.MID_HEALTH,
            },
            {
                title: 'Resultant KPI',
                href: '/resultant-kpi',
                feature: EntFeature.RESULTANT_KPI,
            },
            {
                title: 'Reconciliation Report',
                href: '/reconciliation-report',
                feature: EntFeature.RECONCILIATION_REPORT,
            },
            {
                title: 'Alerts Report',
                href: '/reports/alerts',
                feature: EntFeature.ALERTS_REPORT,
            },
            {
                title: 'Monitoring',
                href: '/reports/monitoring',
                feature: EntFeature.MONITORING,
            },
        ],
    },
    {
        title: 'Notifications',
        feature: EntFeature.ERT,
        icon: <Icon className={iconsTheme.exclamationCircle} />,
        sublinks: [
            {
                title: 'ERT',
                href: '/ert',
                feature: EntFeature.ERT,
            },
        ],
    },
    {
        title: 'User Management',
        icon: <Icon className={iconsTheme.users} />,
        feature: EntFeature.USERS,
        sublinks: [
            {
                title: 'Users',
                href: '/users',
                feature: EntFeature.USERS,
            },
        ],
    },
    {
        title: 'Settings',
        icon: <Icon className={iconsTheme.cog} />,
        feature: EntFeature.SETTINGS,
        sublinks: [
            {
                title: 'Profile Settings',
                href: '/settings',
                feature: EntFeature.PROFILE,
            },
            {
                title: 'Merchant Hierarchies',
                href: '/merchant-hierarchy',
                feature: EntFeature.MERCHANT_HIERARCHY,
            },
            {
                title: 'Merchant Settings',
                href: '/merchant-settings',
                feature: EntFeature.MERCHANT_HIERARCHY_AGGRID,
            },
            {
                title: 'Templates Library',
                href: '/template-library',
                feature: EntFeature.TEMPLATE_LIBRARY,
            },
            {
                title: 'Onboarding',
                href: '/onboarding',
                feature: EntFeature.ONBOARDING,
            },
            {
                title: 'Webhooks',
                href: '/webhooks',
                feature: EntFeature.WEBHOOKS,
            },
        ],
    },
    {
        title: 'Admin',
        icon: <Icon className={iconsTheme.solidLock} />,
        feature: EntFeature.ADMIN_REVIEW,
        sublinks: [
            {
                title: 'Review',
                href: '/review',
                feature: EntFeature.ADMIN_REVIEW,
            },
        ],
    },
    {
        title: 'Configuration',
        feature: EntFeature.CLIENT_CONFIGURATION,
        icon: <Icon className={iconsTheme.code} />,
        sublinks: [
            {
                title: 'Features',
                href: '/features',
                feature: EntFeature.FEATURES_LIST,
            },
        ],
    },
    {
        title: 'Support',
        icon: <Icon className={iconsTheme.lifeRing} />,
        feature: EntFeature.HELP,
        sublinks: [
            {
                title: 'Help',
                href: '/help',
                feature: EntFeature.HELP,
            },
            {
                title: 'API Documentation',
                href: '/resource-api-documentation',
                feature: EntFeature.API_DOCUMENTATION,
                minimizeDrawer: true,
            },
        ],
    },
]

export default NavLinks
