import {
    DataGridActions,
    DataGridSlim,
    Searchbar,
    AlertSnackbar
} from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import SortComponent from 'components/DataGrid/SortComponent'
import { User, UserModel } from 'hooks/cb-api'
import React from 'react'
import { CellProps as RTCellProps } from 'react-table'
import {
    EditIconAction,
    MidPermissionsIconAction,
    SitePermissionsIconAction,
    TempPasswordRequestIconAction,
    ToggleLockIconAction,
    LoginAsIconAction,
} from './Cells'
import { useUsersDownload } from '../Users.vm'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import EntFeature from 'models/EntFeature'

export type CellProps = RTCellProps<UserModel>

enum UserStatus {
    ACTIVE = 1,
    DELETED = 2,
    DISABLED = 3,
    EXPIRED = 4,
    PASSWORD = 5,
    LOCKED_OUT = 6,
}

interface SortInfo {
    sortBy: string
    sortDesc: string
}

interface UsersGridProps {
    user: any
    users: User[]
    loading: boolean
    onPageSizeChange: (size: number) => void
    onPageChange: (page: number) => void
    contextMenuItems?: any
    printId: string
    pageSize: number
    globalSearch: string
    onSortChange: (sort: boolean, accessor: string) => void
    total: number
    onSearchChange: (search: string) => void
    sortInfo: SortInfo
    page: number
    testId: string
    isFederatedUser?: boolean
    params: { [key: string]: any }
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    rehydrateView: () => void
    [key: string]: any
}
/**
 * DataGrid of user data.
 */
export const UsersDataGrid = ({
    user,
    users,
    total,
    loading,
    rowsPerPage,
    onSortChange,
    onPageSizeChange,
    onPageChange,
    onSearchChange,
    sortInfo,
    page,
    selectAllRowsInStore,
    unselectAllRowsInStore,
    testId,
    isFederatedUser = false,
    params,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    rehydrateView,
}: UsersGridProps) => {
    const { client } = useFeatureToggle('CLIENT')
    const { READ_ONLY_OPTION } = useFeatureToggle('USERS')
    const { user: currentUser } = useAuthedUser()
    const roleAlias = currentUser?.role?.alias ?? ''
    const {
        downloadXlsx,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        isEmailExport,
        setIsEmailExport
    } = useUsersDownload()
    const [hiddenColumns, setHiddenColumns] = React.useState([])
    const uiState = useUiStateContext()
    const hasUsersExportButton = uiState.whoami?.hasRole(EntFeature.USERS_EXPORT_BUTTON) || false

    const sortHandler = React.useCallback(
        (isAsc: boolean, column: string) => {
            onSortChange(isAsc, column)
        },
        [onSortChange]
    )

    let columns: any = [
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="merchant"
                    value="Company"
                    sortInfo={sortInfo}
                />
            ),
            accessor: "merchant.business_name",
            type: 'string',
            priority: 5,
            width: 90,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="role"
                    value="Role"
                    sortInfo={sortInfo}
                />
            ),
            accessor: "role.name",
            type: 'string',
            priority: 7,
            width: 90,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="fname"
                    value="First Name"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'fname',
            type: 'string',
            priority: 8,
            width: 120,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="lname"
                    value="Last Name"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'lname',
            type: 'string',
            priority: 9,
            width: 120,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="username"
                    value="Username"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'username',
            type: 'string',
            ellipsifyText: true,
            priority: 6,
            width: 150,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="status"
                    value="Status"
                    sortInfo={sortInfo}
                />
            ),
            accessor: "status.name",
            type: 'string',
            priority: 10,
            width: 86,
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="email"
                    value="Email"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'email',
            type: 'string',
            ellipsifyText: true,
            priority: 11,
            width: 150,
        },
        {
            Header: 'Toggle Lock',
            accessor: 'toggleLock',
            // ToggleLock's defaultLocked prop can be true or false and will come from row when that is setup in the API
            Cell: ({ row: { original: data, id } }: any) => {
                return (
                    <ToggleLockIconAction
                        isCurrentUser={user.id === data.id}
                        user={data}
                        defaultLocked={
                            // @ts-ignore
                            data.status.id === UserStatus.LOCKED_OUT
                        }
                        rowIndex={id}
                    />
                )
            },
            width: 85,
            type: 'icon',
        },
        {
            Header: 'Edit',
            accessor: 'edit',
            Cell: ({ row }: CellProps) => (
                <EditIconAction
                    id={row.original.id}
                    disabled={isFederatedUser}
                    rowIndex={row.id}
                />
            ),
            priority: 4,
            width: 90,
            type: 'icon',
        },
        {
            Header: 'MID Permissions',
            accessor: 'midPermission',
            Cell: ({ row }: any) => {
                return (
                    <MidPermissionsIconAction
                        userModel={row.original}
                        rowIndex={row.id}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                )
            },
            priority: 3,
            width: 105,
            type: 'icon',
        },
        {
            Header: 'Site Permissions',
            accessor: 'sitePermissions',
            Cell: ({ row }: CellProps) => (
                <SitePermissionsIconAction
                    user={user}
                    rowData={row}
                    rowIndex={row.id}
                />
            ),
            priority: 2,
            width: 105,
            type: 'icon',
        },
        {
            Header: 'Temp Password',
            accessor: 'tempPassword',
            Cell: ({ row: { original: data, id } }: CellProps) => (
                <TempPasswordRequestIconAction
                    isCurrentUser={user.id === data.id}
                    user={data}
                    disabled={isFederatedUser}
                    rowIndex={id}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            priority: 1,
            width: 105,
            type: 'icon',
        },
    ]

    if (READ_ONLY_OPTION?.enabled) {
        columns.splice(7, 0, {
            Header: 'Read Only',
            accessor: 'is_read_only',
            Cell: ({ row: { original: data } }: CellProps) =>
                data.is_read_only ? 'Yes' : 'No',
            priority: 12,
            width: 90,
        })
    }

    if (
        currentUser?.role?.alias === 'cb911_admin' ||
        (client === 'worldpay' && currentUser?.role?.alias === 'partner_admin')
    ) {
        columns = [
            ...columns,
            {
                Header: 'Login As',
                accessor: 'loginAs',
                Cell: ({ row: { original: data, id } }: CellProps) => (
                    <LoginAsIconAction
                        loginAsUserId={data.id}
                        loginAsUsername={data.username ?? ''}
                        disabled={
                            data.status.id !== UserStatus.ACTIVE ||
                            //@ts-ignore
                            data.role.alias === 'cb911_admin'
                        }
                        rowIndex={id}
                    />
                ),
                priority: 1,
                width: 80,
            },
        ]
    }

    if (client === 'bluesnap') {
        columns = columns.filter(
            (col: any) => col.accessor !== 'sitePermissions'
        )
        if (!['cb911_admin'].includes(roleAlias)) {
            columns = columns.filter((col: any) => col.id !== 'company')
        }
    }

    if (isFederatedUser) {
        columns = columns.filter(
            (col: any) => {
                return col.id !== 'status' &&
                    col.accessor !== 'toggleLock' &&
                    col.accessor !== 'edit' &&
                    col.accessor !== 'sitePermissions' &&
                    col.accessor !== 'tempPassword' &&
                    col.accessor !== 'loginAs'
            }
        )
    }

    const headersXlsx = [
        'merchant',
        'role',
        'fname',
        'lname',
        'username',
        'status',
        'email',
    ]

    return (
        <div id={'usersDataGrid'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={onSearchChange}
                    testId={testId}
                />
                <DataGridActions
                    gridName="users"
                    columns={columns}
                    tableData={users}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    dataActionExportAllExcel={!hasUsersExportButton ? false : true}
                    dataActionCSV={!hasUsersExportButton ? true : false}
                    dataActionExportAll={false}
                    testId={testId}
                    fileName={'Users'}
                    onExportXlsx={() =>
                        downloadXlsx(
                            {
                                export_type: 'page',
                                export_total: total,
                                ...params,
                            },
                            'Users',
                            true,
                            headersXlsx
                        )
                    }
                    onExportXlsxLoading={isLoadingDownloadXlsx}
                    onExportAllXlsx={() => {
                        downloadXlsx(
                            {
                                export_type: 'all',
                                export_total: total,
                                ...params,
                            },
                            'Users',
                            false,
                            headersXlsx
                        )
                    }}
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    // dataActionSwitchGrids={true}
                />
            </div>
            <DataGridSlim
                page={page}
                columns={columns}
                data={users}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pageSize={rowsPerPage}
                expandable={true}
                slim={false}
                loading={loading}
                onPageLimitChange={onPageSizeChange}
                onPageChange={onPageChange}
                totalEntries={total}
                hiddenColumns={hiddenColumns}
                hiddenColumnsEnabled
                fixedLoadingIndicator
                selectAllRowsInStore={selectAllRowsInStore}
                unselectAllRowsInStore={unselectAllRowsInStore}
                disableSelectAllCheckbox
                hideRowCheckboxes
                stickyHeaders={true}
                testId={testId}
                refreshDataGrid={() => rehydrateView()}
            />
            <AlertSnackbar
                content={{
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                }}
                onClose={() => setIsEmailExport(false)}
                autoHideDuration={5_000}
                open={isEmailExport}
                showCloseIcon
            />
        </div>
    )
}
