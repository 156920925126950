import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'

const useSidebarNav = () => {
    const uiState = useUiStateContext()
    return {
        allowedResource: (resource: EntFeature | EntFeature[]) =>
            uiState.whoami?.hasResource(resource) ?? false,
    }
}

export default useSidebarNav
