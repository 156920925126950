import React from 'react'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { INavLink } from './NavLinks'
import EntFeature from 'models/EntFeature'
import { iconsTheme } from 'theme-exports'
import { Icon } from 'components'

const NavLinksNewGrids: INavLink[] = [
    {
        title: 'Dashboard',
        href: '/dashboard',
        feature: EntFeature.DASHBOARD,
        icon: <Icon className={iconsTheme.home} />,
    },
    {
        title: 'Chargebacks',
        icon: <Icon className={iconsTheme.moneyCheckAlt} />,
        feature: EntFeature.CHARGEBACKS,
        sublinks: [
            {
                title: 'Chargebacks',
                href: '/chargebacks-new',
                feature: EntFeature.CHARGEBACKS_AGGRID,
            },
              {
                title: ' Invalid Chargebacks',
                href: '/invalid-chargebacks-new',
                feature: EntFeature.INVALID_CHARGEBACKS_AGGRID,
                sublinks: [],
            },
            {
                title: 'Case Management',
                href: '/case-management',
                feature: EntFeature.CASE_MANAGEMENT,
                sublinks: [],
            },
            {
                title: 'QC Review',
                href: '/case-qc-review-new',
                feature: EntFeature.CASE_QC_REVIEW_AGGRID,
                sublinks: [],
            },
            {
                title: 'Updater',
                href: '/case-updater',
                feature: EntFeature.CASE_UPDATER,
                sublinks: [],
            },
        ],
    },
    {
        title: 'Alerts',
        href: '/alerts-new',
        feature: EntFeature.ALERTS_AGGRID,
        icon: <Icon className={iconsTheme.bell} />,
    },
    {
        title: 'Reports',
        icon: <Icon className={iconsTheme.chartPie} />,
        feature: EntFeature.REPORTS,
        sublinks: [
            {
                title: 'Reports',
                href: '/reports',
                feature: EntFeature.REPORTS,
            },
            {
                title: 'Month-to-Date',
                href: '/mtd',
                feature: EntFeature.MTD_REPORTS,
            },
            {
                title: 'MID Health',
                href: '/mid-health-new',
                feature: EntFeature.MID_HEALTH_AGGRID,
            },
            {
                title: 'Resultant KPI',
                href: '/resultant-kpi',
                feature: EntFeature.RESULTANT_KPI,
            },
            {
                title: 'Reconciliation Report',
                href: '/reconciliation-report-new',
                feature: EntFeature.RECONCILIATION_REPORT_AGGRID,
            },
            {
                title: 'Alerts Report',
                href: '/reports/alerts',
                feature: EntFeature.ALERTS_REPORT,
            },
            {
                title: 'Monitoring',
                href: '/reports/monitoring',
                feature: EntFeature.MONITORING,
            },
        ],
    },
    {
        title: 'Notifications',
        feature: EntFeature.ERT,
        icon: <Icon className={iconsTheme.exclamationCircle} />,
        sublinks: [
            {
                title: 'ERT',
                href: '/ert',
                feature: EntFeature.ERT,
            },
        ],
    },
    {
        title: 'User Management',
        icon: <Icon className={iconsTheme.users} />,
        feature: EntFeature.USERS,
        sublinks: [
            {
                title: 'Users',
                href: '/users-new',
                feature: EntFeature.USERS_AGGRID,
            },
        ],
    },
    {
        title: 'Settings',
        icon: <Icon className={iconsTheme.cog} />,
        feature: EntFeature.SETTINGS,
        sublinks: [
            {
                title: 'Profile Settings',
                href: '/settings-new',
                feature: EntFeature.PROFILE_AGGRID,
            },
            {
                title: 'Merchant Hierarchies',
                href: '/merchant-hierarchy',
                feature: EntFeature.MERCHANT_HIERARCHY,
            },
            {
                title: 'Merchant Settings',
                href: '/merchant-settings',
                feature: EntFeature.MERCHANT_HIERARCHY_AGGRID,
            },
            {
                title: 'Templates Library',
                href: '/template-library-new',
                feature: EntFeature.TEMPLATE_LIBRARY_AGGRID,
            },
            {
                title: 'Onboarding',
                href: '/onboarding',
                feature: EntFeature.ONBOARDING,
            },
            {
                title: 'Webhooks',
                href: '/webhooks',
                feature: EntFeature.WEBHOOKS,
            },
        ],
    },
    {
        title: 'Admin',
        icon: <Icon className={iconsTheme.solidLock} />,
        feature: EntFeature.ADMIN_REVIEW,
        sublinks: [
            {
                title: 'Review',
                href: '/review',
                feature: EntFeature.ADMIN_REVIEW,
            },
        ],
    },
    {
        title: 'Configuration',
        feature: EntFeature.CLIENT_CONFIGURATION,
        icon: <Icon className={iconsTheme.code} />,
        sublinks: [
            {
                title: 'Features',
                href: '/features',
                feature: EntFeature.FEATURES_LIST,
            },
        ],
    },
    {
        title: 'Support',
        icon: <Icon className={iconsTheme.lifeRing} />,
        feature: EntFeature.HELP,
        sublinks: [
            {
                title: 'Help',
                href: '/help',
                feature: EntFeature.HELP,
            },
            {
                title: 'API Documentation',
                href: '/resource-api-documentation',
                feature: EntFeature.API_DOCUMENTATION,
                minimizeDrawer: true,
            },
        ],
    },
]

export default NavLinksNewGrids
