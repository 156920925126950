import React, { useState } from 'react'
import View from 'views/View'
import { AccentArea } from 'components'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { SWRContextProvider as QCReviewContextProvider } from 'context/SWRContext/SWRContext'
import useQCReview from './QCReview.vm'
import { QCReviewGrid } from './grids/QCReviewGrid'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

const QCReview = (): React.ReactElement => {
    const { cases, swrActions, params } = useQCReview()

    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    return (
        <QCReviewContextProvider SWRActions={swrActions}>
            <View title="QC Review" breadcrumb="QC Review" testId="qcReview">
                <AccentArea
                    testId="qcReview"
                    hasHeaderDivider={false}
                    containsFullWidthGrid={true}
                >
                    <QCReviewGrid
                        data={cases}
                        params={params}
                        testId="qcReview"
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                </AccentArea>
                <AlertSnackbar
                    content={alertSnackbarMainProps}
                    open={alertSnackbarMainOpen}
                    onClose={() => setAlertSnackbarMainOpen(false)}
                    showCloseIcon
                />
            </View>
        </QCReviewContextProvider>
    )
}

export default withRequiredRole(EntFeature.CASE_QC_REVIEW, QCReview)
