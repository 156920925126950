import React, { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from 'components/Icon'
import { differenceInDays, format } from 'date-fns'
import idDirectory from './idAttributes'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { AccentArea, UploadRepresentmentsRouter, AlertSnackbar } from 'components'
import View from 'views/View'
import useCaseQCReview from './CaseQCReview.vm'
import { CaseInfo } from './sections/CaseInfo'
import { AddErt, DoNotRepresent } from './modals'


const CaseQCReview = (): React.ReactElement => {
    const uiState = useUiStateContext()
    const { DO_NOT_REPRESENT } = useFeatureToggle('CASE_QC_REVIEW')

    const [openAddERTModal, setOpenAddERTModal] = useState<boolean>(false)
    const [openDNRModal, setOpenDNRModal] = useState<boolean>(false)

    const showAddErtBtn = (uiState.whoami?.roles ?? []).some(
        (role) => role === EntFeature.QC_REVIEW_ADD_ERT
    )

    const showDNRBtn = DO_NOT_REPRESENT?.enabled ?? false

    const {
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        documentInfo,
        uploadModalOpen,
        documentLoading,
        documentLoadKey,
        caseInfo,
        loadingCaseInfo,
        showApproveConfirm,
        errorGettingNewCase,
        errorGettingNewCaseText,
        isLoadingApproveQCReview,
        approveQCReview,
        getNextQCReviewCase,
        setUploadModalOpen,
        refreshPDFView,
        goBackToQCReviewGrid,
        setShowApproveConfirm,
        setRefreshCaseInfo,
    } = useCaseQCReview()

    return (
        <View
            title="Case QC Review"
            breadcrumb="case QC review"
            testId="caseQCReview"
        >
            {errorGettingNewCase && (
                <AccentArea
                    containsFullWidthGrid={true}
                    hasHeaderDivider={false}
                    className={'emp-noMoreQCCases'}
                >
                    <div className={'emp-noMoreQCCasesNotification'}>
                        {errorGettingNewCaseText}
                    </div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={'emp-actionBtn'}
                        onClick={goBackToQCReviewGrid}
                    >
                        <Icon
                            className={`fas fa-arrow-left ${'emp-iconBtn'}`}
                        />
                        Back
                    </Button>
                </AccentArea>
            )}
            {!loadingCaseInfo && !errorGettingNewCase && (
                <AccentArea
                    testId="caseQCReview"
                    containsFullWidthGrid={true}
                    hasHeaderDivider={false}
                    className={'emp-adminCasesContainer'}
                >
                    <div
                        className={'emp-headerContainer'}
                        id={idDirectory.divHeaderContainer}
                    >
                        <div className={'emp-headerContent'}>
                            <div>
                                <span className={'emp-headerText'}>ID:</span>
                                {caseInfo.id}
                                <span
                                    className={'emp-headerText'}
                                    style={{ marginLeft: '8px' }}
                                >
                                    DUE DATE:
                                </span>
                                {`${caseInfo.date_due} (${differenceInDays(
                                    new Date(caseInfo.date_due + 'T00:00'),
                                    new Date(
                                        format(new Date(), 'yyyy-MM-dd') +
                                            'T00:00'
                                    )
                                )} days left)`}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {showAddErtBtn && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={'emp-actionBtn'}
                                        onClick={() => setOpenAddERTModal(true)}
                                    >
                                        <span className={'emp-iconBtn'}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </span>
                                        Add ERT
                                    </Button>
                                )}
                                {showApproveConfirm ? (
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={'emp-actionBtn'}
                                            onClick={approveQCReview}
                                            disabled={isLoadingApproveQCReview}
                                        >
                                            {isLoadingApproveQCReview ? (
                                                <>
                                                    Approving...{' '}
                                                    <CircularProgress
                                                        className={
                                                            'emp-circularProgress'
                                                        }
                                                        color="secondary"
                                                    />
                                                </>
                                            ) : (
                                                'Confirm Approval'
                                            )}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={'emp-grayActionBtn'}
                                            onClick={() =>
                                                setShowApproveConfirm(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={'emp-actionBtn'}
                                        onClick={() =>
                                            setShowApproveConfirm(true)
                                        }
                                    >
                                        <Icon
                                            className={`far fa-file ${'emp-iconBtn'}`}
                                        />
                                        Approve
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'emp-gridContainer'}>
                        <div
                            className={'emp-caseInfoContainer'}
                            id={idDirectory.divCaseInfoContainer}
                        >
                            <CaseInfo caseInfo={caseInfo} />
                        </div>
                        <div
                            className={'emp-casePreviewContainer'}
                            id={idDirectory.divCasePreviewContainer}
                        >
                            {documentLoading ? (
                                <div
                                    className={'emp-pdfLoadingSpinnerContainer'}
                                >
                                    <CircularProgress />
                                </div>
                            ) : (
                                <iframe
                                    className={'emp-documentIframe'}
                                    src={documentInfo.link}
                                    key={documentLoadKey}
                                    title="PDF Preview"
                                >
                                    <div>No PDF viewer installed on system</div>
                                </iframe>
                            )}
                        </div>
                    </div>
                    <div
                        className={'emp-footerContainer'}
                        id={idDirectory.divFooterContainer}
                    >
                        <div className={'emp-footerContent'}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={'emp-actionBtn'}
                                onClick={goBackToQCReviewGrid}
                            >
                                <Icon
                                    className={`fas fa-arrow-left ${'emp-iconBtn'}`}
                                />
                                Back
                            </Button>
                            <div>
                                {showDNRBtn && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={'emp-actionBtn'}
                                        onClick={() => setOpenDNRModal(true)}
                                    >
                                        Do Not Represent
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={'emp-actionBtn'}
                                    onClick={() => {
                                        setUploadModalOpen(true)
                                    }}
                                >
                                    <Icon
                                        className={`fas fa-circle-arrow ${'emp-iconBtn'}`}
                                    />
                                    Replace
                                </Button>
                            </div>
                        </div>
                    </div>
                    <UploadRepresentmentsRouter
                        cases={[caseInfo]}
                        caseIds={[caseInfo.id]}
                        openModal={uploadModalOpen}
                        toggleModal={() => {
                            if (uploadModalOpen) {
                                setUploadModalOpen(false)
                            }
                        }}
                        isReupload={true}
                        rehydrateView={() => {
                            refreshPDFView()
                        }}
                        comments={documentInfo.case_comments}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                        disableRemedy
                    />
                </AccentArea>
            )}
            <AlertSnackbar
                content={alertSnackbarMainProps}
                open={alertSnackbarMainOpen}
                onClose={() => setAlertSnackbarMainOpen(false)}
                showCloseIcon
            />
            {openAddERTModal && (
                <AddErt
                    openAddERT={openAddERTModal}
                    onClose={() => setOpenAddERTModal(false)}
                    caseId={caseInfo.id}
                    setRefreshCaseInfo={setRefreshCaseInfo}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}
            {openDNRModal && (
                <DoNotRepresent
                    openDNR={openDNRModal}
                    onClose={() => setOpenDNRModal(false)}
                    caseId={caseInfo.id}
                    getNextQCReviewCase={getNextQCReviewCase}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}
        </View>
    )
}

export default withRequiredRole(EntFeature.CASE_QC_REVIEW, CaseQCReview)
