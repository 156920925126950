import EntFeature from './EntFeature'
import { Resource, Role, Feature, WhoAmI, Merchant } from 'hooks/cb-api/types'
import { Status } from './GenericTypes'

class WhoAmIModel {
  public id: number | undefined
  public allow_resources: number[]
  public deny_resources: number[]
  public email: string | undefined
  public features: Feature[]
  public fname: string | undefined
  public lname: string | undefined
  public is_federated: boolean | undefined
  public is_read_only : boolean | undefined
  public merchant: Merchant | undefined
  public mids_allowed: number[]
  public mids_count: number | null
  public mids_excluded: number[]
  public resources: Resource[]
  public role: Role | undefined
  public roles: string[]
  public status: Status | undefined
  public username: string | undefined
  public theme_variant: number | undefined

  constructor() {
    this.id = undefined
    this.allow_resources = []
    this.deny_resources = []
    this.email = undefined
    this.features = []
    this.fname = undefined
    this.lname = undefined
    this.is_federated = undefined
    this.is_read_only  = undefined
    this.merchant = undefined
    this.mids_allowed = []
    this.mids_count = null
    this.mids_excluded = []
    this.resources = []
    this.role = undefined
    this.roles = []
    this.status = undefined
    this.username = undefined
    this.theme_variant = undefined
  }

  public fullName(): string {
    // Space between only if both exist
    return `${this.fname ?? ''}${this.lname ? ` ${this.lname ?? ''}` : ''}`
  }

  public hasRole(role: EntFeature | EntFeature[]) {
    if (
        role === EntFeature.UNIVERSAL ||
        role.includes(EntFeature.UNIVERSAL)
    ) {
        return true
    }
    const roles = Array.isArray(role) ? role : [role]

    try {
      return roles.map((i) => this.roles.includes(i)).includes(true)
    } catch {
      return false
    }
  }

  public hasResource(resource: EntFeature | EntFeature[]) {
    if (
        resource === EntFeature.UNIVERSAL ||
        resource.includes(EntFeature.UNIVERSAL)
    ) {
        return true
    }

    const resources = Array.isArray(resource) ? resource : [resource]

    const userResources = this.resources?.map((item) => item.alias)
    try {
        return resources.some((i) => userResources.includes(i))
    } catch {
        return false
    }
}

  public fromData(data: WhoAmI) {
    this.id = data.id
    this.allow_resources = data.allow_resources
    this.deny_resources = data.deny_resources
    this.email = data.email
    this.features = data.features
    this.fname = data.fname
    this.lname = data.lname
    this.is_federated = data.is_federated
    this.is_read_only  = data.is_read_only 
    this.merchant = data.merchant
    this.mids_allowed = data.mids_allowed
    this.mids_count = data.mids_count
    this.mids_excluded = data.mids_excluded
    this.resources = data.resources
    this.role = data.role
    this.roles = data.roles
    this.status = data.status
    this.username = data.username
    this.theme_variant = data.theme_variant
    return this
  }
}

export default WhoAmIModel
